/* @font-face {
  font-family: futura;
  src: url("./assets/fonts/futura/futur.ttf");
} */

/* @font-face {
  font-family: bakbakOne;
  src: url("./assets/fonts/bakbakOneSemibold.ttf");
  font-weight: bold;
} */
/* @font-face {
  font-family: Konstanz;
  src: url("./assets/fonts/Konstanz-SemiBold.otf");
  font-weight: bold;
} */
@font-face {
  font-family: bakbakOne;
  font-weight: bold;
  src: url("./assets/fonts/bakbakOne.ttf");
}

html {
  overflow-y: overlay;
}

body {
  margin: 0;
  font-family: bakbakOne;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}
