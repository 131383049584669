


.MuiPaper-root {
  background: black;
  filter: blur(1.2);
}

.MuiOutlinedInput-notchedOutline:hover {
  border: none;
}

.Toastify__toast-body {
  white-space: pre-line;
}